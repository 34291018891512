import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

export const router = new Router({
  mode: 'abstract',
  routes: [
    {
      path: "/:id",
      name: "Chatbot",
      component: Vue.component("ChatbotPage", () =>
        import("../pages/ChatbotPage.vue")
      ),
      meta: {
        allowAnonymous: true,
      },
    },
    // {
    //     path: '/error',
    //     name: 'Error',
    //     component: Vue.component('Error', () =>
    //         import ('../pages/Error.vue')),
    //     meta: {
    //         allowAnonymous: true
    //     }
    // },
    {
      path: "/*",
      redirect: "/-1"
    },
  ],
});
router.replace('/')
router.beforeEach((to, from, next) => {
  next();
});