import Vue from "vue";
import App from "./App.vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import VueSocketIO from "vue-socket.io";
// import "material-design-icons-iconfont/dist/material-design-icons.css";
import '@mdi/font/css/materialdesignicons.css'
import { router } from "./router";
import { baseApiUrl } from "./assets/linkTree";

Vue.config.productionTip = false;

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: baseApiUrl + "/",
    vuex: {
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_",
    },
  })
);
Vue.use(Vuetify);
const opts = {};

new Vue({
  el: "#app",
  router,
  vuetify: new Vuetify(opts),
  render: (h) => h(App),
}).$mount("#app");
